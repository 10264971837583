import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { auth } from '../config/firebase-config';
import Logo from '../img/lyte-logo.png';
import check from '../img/check.gif';
import '../css/onboarding.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const emailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleSubmit = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        handleError(err.code);
      });
  };

  const handleError = (error) => {
    switch (error) {
      case 'auth/missing-email':
        setError(<p id="error">enter a valid email</p>);
        break;
      case 'auth/user-not-found':
        setError(<p id="error">No Account with this email</p>);
        break;
      default:
        setError(<p id="error">Request not completed try again later</p>);
    }
  };

  return (
    <section className="password-reset-container">
      <section className="onboard-logo-container">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
      </section>

      <section className="resetpassword">
        {success === true && (
          <section className="success">
            <img src={check} alt="" />
            <h1>Check your email</h1>
            <p>
              We sent an email to {email}. If you don’t get the email soon,
              check your spam
            </p>
          </section>
        )}

        {success === false && (
          <section>
            <h1>Password Recovery</h1>
            <p>
              A link with password recovery will be sent to your verified email
              address
            </p>

            {/* show errow */}
            {error}

            <div className="form-input">
              <label>Email</label>
              <input
                type="email"
                name="email"
                onChange={(e) => emailChange(e)}
                required
              />
            </div>

            <button className="reset-password-btn" onClick={handleSubmit}>
              Reset Password
            </button>
          </section>
        )}
      </section>
    </section>
  );
};

export default ResetPassword;
