import './App.css';
import LandingPage from './pages/LandingPage';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import { useState } from 'react';
import RequireAuth from './pages/protectedPage/RequireAuth';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Locations from './pages/Locations';
import Onboarding from './pages/protectedPage/Onboarding';
import ResetPassword from './components/ResetPassword';

function App() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage modal={showModal} setShowModal={setShowModal} />
          }
        />

        <Route path="/locations" element={<Locations />} />

        {/* protect login and signup page is user is logged in */}

        <Route element={<Onboarding />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="resetpassword" element={<ResetPassword />} />
        </Route>

        {/* private Route */}
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
