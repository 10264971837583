import { db } from '../config/firebase-config';
import { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import Spinner from '../img/spinner.svg';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import '../css/location.css';
import { onValue, ref } from 'firebase/database';

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const getLocations = async () => {
      const colRef = ref(db, '/locations/');

      onValue(colRef, (response) => {
        const data = response.val();
        setLocations(data);
      });
    };

    getLocations();
  }, []);

  return (
    <section>
      <NavBar />
      <section className="locations-container">
        <section className="search-box">
          <input
            type="search"
            placeholder="search location"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IoSearch />
        </section>

        <h1>Below is a list of all the locations we deliver to in lagos</h1>

        <section className="locations">
          {locations.length === 0 ? (
            <section className="spinner">
              <img src={Spinner} alt="loading" />
            </section>
          ) : (
            <section className="all-locations">
              {locations
                // eslint-disable-next-line array-callback-return
                .filter((val) => {
                  if (val === '') {
                    return val;
                  } else if (
                    val.toLowerCase().includes(searchQuery.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
            </section>
          )}
        </section>
      </section>
      <Footer />
    </section>
  );
};

export default Locations;
