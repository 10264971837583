import { useEffect, useState, useRef } from 'react';
import { db } from '../config/firebase-config';
import Spinner from '../img/spinner.svg';
import { useNavigate } from 'react-router-dom';
import { onValue, ref } from 'firebase/database';

const CalculateQuote = ({ calc }) => {
  const [locations, setLocations] = useState([]);
  const [pickUpLocation, setPickUpLocation] = useState('');
  const [dropOffLocation, setDropOfflocation] = useState([]);
  const [dropOffPrice, setDropOffPrice] = useState('');
  const [total, setTotal] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const isMounted = useRef(false);

  const navigate = useNavigate();

  // calculate delivery price
  const calculatePrice = () => {
    if (dropOffPrice === '' || dropOffPrice === 'Select Drop off location') {
      setError(true);
      setTotal('');
    } else {
      setTotal(`₦${dropOffPrice}`);

      //show spinner
      setLoading(true);

      //remove spinner after 2sec
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  // fetch pickup locatuon
  useEffect(() => {
    const colRef = ref(db, '/pick-up-locations/');

    onValue(colRef, (response) => {
      const data = response.val();

      setLocations(data);
    });
  }, []);

  // fetch drop off location
  useEffect(() => {
    if (isMounted.current) {
      const colRef = ref(db, `/delivery-locations/${pickUpLocation}/`);

      const getLocations = () => {
        onValue(colRef, (response) => {
          const data = response.val();
          setDropOfflocation(data);
        });
      };

      if (pickUpLocation !== 'select pick-up location') {
        getLocations();
      }
    } else {
      isMounted.current = true;
    }
  }, [pickUpLocation]);

  const pickUpInputCahnge = (e) => {
    setPickUpLocation(e.target.value.toLowerCase());
    setDropOfflocation([]);
    setDropOffPrice('');
    setTotal('');
    setError(false);
  };

  const dropOffInputCahnge = (e) => {
    setDropOffPrice(e.target.value);
    setTotal('');
    setError(false);
  };

  return (
    <section className="calculate-quote">
      <section>
        {error === true && <p id="error">Select a valid location</p>}

        {/* show spinner if no data */}
        {locations.length === 0 && (
          <section className="spinner">
            <img src={Spinner} alt="loading" />
          </section>
        )}

        {/* show component once  location data is available */}
        {locations.length !== 0 && (
          <section>
            <section className="pick-up-location">
              <p>pick-up location</p>
              <select name="pick-up" onChange={(e) => pickUpInputCahnge(e)}>
                <option>Select pick-up location</option>
                {locations.map((items, index) => (
                  <option value={items} key={index}>
                    {items}
                  </option>
                ))}
              </select>
            </section>

            <section className="drop-off-location">
              <p>Drop off Location</p>
              <select name="drop-off" onChange={(e) => dropOffInputCahnge(e)}>
                <option>Select Drop off location</option>
                {dropOffLocation.map((item, i) => (
                  <option value={item && item.price} key={i && i}>
                    {item && item.location}
                  </option>
                ))}
              </select>
            </section>

            <section id="total">
              <p>Total:</p>
              {loading === true ? <img src={Spinner} alt="loading" /> : total}
            </section>

            <section className="button">
              {/* if total is not empty show request delivery button */}
              {total && loading === false ? (
                <button onClick={() => navigate('/dashboard')}>
                  Request Delivery
                </button>
              ) : (
                <button onClick={calculatePrice}>Calculate Price</button>
              )}

              <button className="close-calc" onClick={() => calc()}>
                Cancel
              </button>
            </section>

            <span className="disclaimer">
              Disclaimer: This quote is only for cake delivery, and the actual
              pricing varies when delivering tier and delicate cakes. Have a
              cake to deliver? Signup now, and make a request!
            </span>
          </section>
        )}
      </section>
    </section>
  );
};

export default CalculateQuote;
