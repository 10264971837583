import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { auth } from '../config/firebase-config';
import { useNavigate } from 'react-router-dom';
import Logo from '../img/lyte-logo.png';
import spinner from '../img/spinner.svg';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';
import '../css/onboarding.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [togglePass, setTogglePass] = useState(false);

  const navigate = useNavigate();

  const emailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };
  const passChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleError = (err) => {
    setLoading(false);
    switch (err) {
      case 'auth/user-not-found':
        setError(<p id="error">No Account With This Email</p>);
        break;
      case 'auth/wrong-password':
        setError(<p id="error">Wrong Email or Password</p>);
        break;
      default:
        setError(<p id="error">Login Failed Try Again</p>);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        //reset form data
        e.target.reset();
        //navigate to dashboard
        navigate('/dashboard');
      })
      .catch((err) => {
        handleError(err.code);
      });
  };

  return (
    <section className="login-form-container">
      {loading === true && (
        <section className="spinner">
          <img src={spinner} alt="loading.." />
        </section>
      )}

      <section className="onboard-logo-container">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
      </section>

      <section className="login-form">
        <section className="form-title">
          <h1>Welcome Back</h1>
          <p>Login To Your Account</p>
        </section>

        {/* show error */}
        {error}

        <form onSubmit={handleSubmit}>
          <div className="form-input">
            <label>Email</label>
            <input
              type="email"
              name="email"
              onChange={(e) => emailChange(e)}
              required
            />
          </div>

          <div className="form-input">
            <label>Password</label>
            <span
              className="password-toggle"
              onClick={() => setTogglePass(!togglePass)}
            >
              {togglePass ? <IoEye /> : <IoEyeOff />}
            </span>
            <input
              type={togglePass ? 'text' : 'password'}
              name="password"
              onChange={(e) => passChange(e)}
              required
            />
          </div>

          <section className="reset-pass-container">
            <a href="/resetpassword">Forgot Password?</a>
          </section>

          <button className="login-btn" type="submit">
            Login
          </button>
        </form>

        <section className="Dont-have-acc">
          <p>Don’t have an Account?</p>
          <a href="/signup">Sign up</a>
        </section>
      </section>
    </section>
  );
};

export default Login;
