import '../css/dashboard.css';
import avatar from '../img/avatar.jpeg';
import { auth, db } from '../config/firebase-config';
import { useEffect, useState } from 'react';
import UpdateDetailsForm from '../components/UpdateDetailsForm';
import RequestDelivery from '../components/RequestDelivery';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { onValue, ref } from 'firebase/database';
import useAuth from '../hooks/useAuth';

const Dashboard = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [showEditBox, setShowEditBox] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const { user } = useAuth();
  const current = auth.currentUser;

  useEffect(() => {
    if (user) {
      const docRef = ref(db, 'users/' + user.uid);

      onValue(docRef, (response) => {
        const data = response.val();

        setUserDetails(data);
      });
    }
  }, [user]);

  const editDetails = () => {
    setShowEditBox(true);
    setBusinessName(userDetails.businessName);
    setAddress(userDetails.address);
    setPhone(userDetails.phone);
  };

  return (
    <section>
      <NavBar />

      <section className="dashboard-container">
        <section className="user-profile">
          <section className="user-avatar">
            <img src={avatar} alt="user avatar" />
          </section>

          <p className="user-name">Hello {user && user.displayName}</p>
          <span className="last-login">
            Last login:{' '}
            {current && current.metadata.lastSignInTime.slice(0, 16)}
          </span>

          <section className="other-details">
            {showEditBox === true && (
              <section>
                <UpdateDetailsForm
                  showEditBox={showEditBox}
                  setShowEditBox={setShowEditBox}
                  phone={phone}
                  setPhone={setPhone}
                  address={address}
                  setAddress={setAddress}
                  businessName={businessName}
                  setBusinessName={setBusinessName}
                />
              </section>
            )}

            {showEditBox === false && (
              <section>
                <p>
                  Business Name:{' '}
                  <strong>{userDetails && userDetails.businessName}</strong>
                </p>

                <p>
                  Phone: <strong>{userDetails && userDetails.phone}</strong>
                </p>

                <p>
                  Address: <strong>{userDetails && userDetails.address}</strong>
                </p>

                <section className="update-btn_container">
                  <button className="update-btn" onClick={editDetails}>
                    Edit Details
                  </button>
                </section>
              </section>
            )}
          </section>
        </section>

        <section>
          <RequestDelivery userDetails={userDetails} user={user} />
        </section>
      </section>
      <Footer />
    </section>
  );
};

export default Dashboard;
