/* eslint-disable no-unused-vars */
import { useState, useRef } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db } from '../config/firebase-config';
import Logo from '../img/lyte-logo.png';
import spinner from '../img/spinner.svg';
import { useNavigate } from 'react-router-dom';
import '../css/onboarding.css';
import { set, ref } from 'firebase/database';
import check from '../img/check.gif';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [error, setError] = useState('');
  const [passRequirement, setPassRequirement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accCreated, setAccCreated] = useState(false);
  const [togglePass, setTogglePass] = useState(false);
  const [toggleConfirmPass, setToggleConfirmPass] = useState(false);

  const navigate = useNavigate();

  const emailRef = useRef();
  const PasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const fullNameRef = useRef();
  const phoneRef = useRef();

  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;

  const emailChange = (e) => {
    setEmail(e.target.value);
    setError('');
    emailRef.current.focus();
    emailRef.current.style.border = '';
  };

  const fullNameChange = (e) => {
    setFullName(e.target.value);
    setError('');
    fullNameRef.current.style.border = '';
  };

  const phoneChange = (e) => {
    setPhone(e.target.value);
    setError('');
    phoneRef.current.style.border = '';
  };

  const passChange = (e) => {
    setPassword(e.target.value);
    setError('');
    PasswordRef.current.style.border = '';
  };

  const confirmPassChange = (e) => {
    setConfirmPass(e.target.value);
    setError('');
    confirmPasswordRef.current.style.border = '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fullName === '') {
      fullNameRef.current.focus();
      fullNameRef.current.style.border = '1px solid red';
    } else if (!email.toLowerCase().match(emailRegex)) {
      emailRef.current.focus();
      emailRef.current.style.border = '1px solid red';
    } else if (phone === '') {
      phoneRef.current.focus();
      phoneRef.current.style.border = '1px solid red';
    } else if (!password.match(passRegex)) {
      PasswordRef.current.focus();
      PasswordRef.current.style.border = '1px solid red';
    } else if (confirmPass !== password) {
      confirmPasswordRef.current.focus();
      confirmPasswordRef.current.style.border = '1px solid red';
      setError(<p id="error">Confirm Password is incorrect</p>);
    } else {
      createUser(e);
    }
  };

  const createUser = async (e) => {
    handleFocus();
    setLoading(true);

    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        // update displayname
        updateProfile(auth.currentUser, {
          displayName: fullName,
        });

        //create user profile in firestore
        const docRef = ref(db, 'users/' + auth.currentUser.uid);
        set(docRef, {
          businessName: '',
          phone,
          address: '',
        });

        // clear form input
        e.target.reset();
      })
      .then(() => {
        setLoading(false);

        setAccCreated(true);
      })
      .then(() => {
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      })
      .catch((err) => {
        handleError(err.code);
      });
  };

  const handleFocus = () => {
    setPassRequirement(true);
  };

  const handleError = (err) => {
    setLoading(false);
    console.log(err);
    switch (err) {
      case 'auth/invalid-email':
        setError(<p id="error">Enter a valid Email</p>);
        break;
      case 'auth/weak-password':
        setError(<p id="error">Enter a Valid Password</p>);
        break;
      case 'auth/email-already-in-use)':
        setError(<p id="error">Account already exist! Login</p>);
        break;
      default:
        setError(<p id="error">Signup failed try again</p>);
    }
  };

  return (
    <section className="signup-form-container">
      {loading === true && (
        <section className="spinner">
          <img src={spinner} alt="loading.." />
        </section>
      )}

      <section className="onboard-logo-container">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
      </section>

      <section className="signup-form">
        {/* show success */}
        {accCreated === true && (
          <section className="success">
            <img src={check} alt="" />
            <h1>Account Created</h1>
            <p>You will be redireted to login</p>
          </section>
        )}

        {accCreated === false && (
          <section>
            <section className="form-title">
              <h1>Create An Account</h1>
              <p>Complete this Form to Quickly Aetup an Account</p>
            </section>

            {/* show error */}
            {error}

            <form onSubmit={handleSubmit}>
              <div className="form-input">
                <label>Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  ref={fullNameRef}
                  onChange={(e) => fullNameChange(e)}
                />
              </div>

              <div className="form-input">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  onChange={(e) => emailChange(e)}
                  ref={emailRef}
                />
              </div>

              <div className="form-input">
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  onChange={(e) => phoneChange(e)}
                  ref={phoneRef}
                />
              </div>

              <div className="form-input">
                <label>Password</label>
                <span
                  className="password-toggle"
                  onClick={() => setTogglePass(!togglePass)}
                >
                  {togglePass ? <IoEye /> : <IoEyeOff />}
                </span>
                <input
                  type={togglePass ? 'text' : 'password'}
                  name="password"
                  onChange={(e) => passChange(e)}
                  ref={PasswordRef}
                  onFocus={handleFocus}
                />
              </div>
              {passRequirement === true && (
                <section className="pass-req">
                  <p>Password Must Contain at Least:</p>
                  <ul>
                    <li>6 characters</li>
                    <li>1 uppercase letter</li>
                    <li>1 lowercase letter</li>
                    <li>One number</li>
                  </ul>
                </section>
              )}

              <div className="form-input">
                <label>Confirm Password</label>
                <span
                  className="password-toggle"
                  onClick={() => setToggleConfirmPass(!toggleConfirmPass)}
                >
                  {toggleConfirmPass ? <IoEye /> : <IoEyeOff />}
                </span>
                <input
                  type={toggleConfirmPass ? 'text' : 'password'}
                  name="confirmPassword"
                  onChange={(e) => confirmPassChange(e)}
                  ref={confirmPasswordRef}
                />
              </div>

              <button className="login-btn" type="submit">
                Create Account
              </button>
            </form>
            <section className="Dont-have-acc">
              <p>
                Already have an Account? <a href="login">Login</a>
              </p>
            </section>
          </section>
        )}
      </section>
    </section>
  );
};

export default SignUp;
