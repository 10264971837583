import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import spinner from '../img/spinner.svg';
import check from '../img/check.gif';

const RequestDelivery = ({ userDetails, user }) => {
  const [error, setError] = useState('');
  const [numberOfpackage, setNumberOfPackage] = useState('');
  const [cakeChecked, setCakeChecked] = useState(true);
  const [foodChecked, setFoodChecked] = useState(false);
  const [flowerChecked, setFlowerChecked] = useState(false);
  const [otherChecked, setOtherChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const deliveryItemOtherRef = useRef();
  const deliveryDateRef = useRef();
  const numberOfPackageRef = useRef();
  const formRef = useRef();
  const errorRef = useRef();

  // hidden input REFs
  const hiddenBsnInput = useRef();
  const hiddenAddressInput = useRef();
  const hiddenNameInput = useRef();
  const hiddenPhoneInput = useRef();

  // submit data
  const handleSubmit = (type, e) => {
    e.preventDefault();

    switch (type) {
      case 'cancel':
        document.getElementById('form').reset();
        break;
      case 'submit':
        checkForError();
        break;
      default:
        checkForError();
    }
  };

  //check if there is error before submitting form
  const checkForError = () => {
    if (
      userDetails.businessName === '' ||
      userDetails.phone === '' ||
      userDetails.address === ''
    ) {
      setError(
        <p id="error" ref={errorRef} autoFocus>
          Pleases Complete Your profile details
        </p>
      );
      window.scroll(0, 50);

      // remove error afte 2seconds
      setTimeout(() => {
        setError('');
      }, 2000);
    } else if (numberOfpackage > 3) {
      numberOfPackageRef.current.style.border = '1px solid red';
      numberOfPackageRef.current.focus();
    } else {
      sendEmail();
    }
  };

  // send form data to email
  const sendEmail = () => {
    // set value for the hidden input
    hiddenAddressInput.current.value = userDetails.address;
    hiddenBsnInput.current.value = userDetails.businessName;
    hiddenPhoneInput.current.value = userDetails.phone;
    hiddenNameInput.current.value = user.displayName;

    // set Loading to true
    setLoading(true);

    emailjs
      .sendForm(
        'service_l8ggfeg',
        'template_05qizbi',
        formRef.current,
        'Hm3Bmp_eJljl5YpGk'
      )
      .then(
        (result) => {
          setLoading(false);
          setSuccess(true);
        },
        (error) => {
          <p id="error" ref={errorRef} autoFocus>
            An error occured please try again
          </p>;
        }
      );
  };

  return (
    <section className="request-delivery">
      <h1 id="#requestDelivery">Request Delivery </h1>
      {error}
      <section className="deliveryInfo">
        {loading && (
          <section className="spinner">
            <img src={spinner} alt="loading.." />
          </section>
        )}

        {success === true && (
          <section className="success">
            <img src={check} alt="success" />
            <h2>Request Sent</h2>
            <p>
              Your order has been sent successfully we will be in touch with you
              shortly
            </p>
            <button onClick={() => setSuccess(false)}>
              place anonter Request
            </button>
          </section>
        )}

        {success === false && (
          <form
            id="form"
            ref={formRef}
            onSubmit={(e) => handleSubmit('submit', e)}
          >
            <p>
              Full Name:{'  '}
              <span>{user && user.displayName}</span>
            </p>
            <p>
              Business Name:{' '}
              <span>{userDetails && userDetails.businessName}</span>
            </p>

            <p>
              Address: <span>{userDetails && userDetails.address}</span>
            </p>

            <p>
              Phone: <span>{userDetails && userDetails.phone}</span>
            </p>

            <h4>Delivery Information</h4>

            <div className="checkmark-field">
              <section className="cake-check-mark">
                <input
                  name="deliveryItem"
                  checked={cakeChecked}
                  type="checkbox"
                  value="Cake"
                  onChange={(e) => setCakeChecked(e.target.checked)}
                />
                <label>Cake</label>
              </section>
              <section className="flower-check-mark">
                <input
                  name="deliveryItem"
                  checked={flowerChecked}
                  type="checkbox"
                  value="Flower"
                  onChange={(e) => setFlowerChecked(e.target.checked)}
                />
                <label>Flower</label>
              </section>
              <section className="food-check-mark">
                <input
                  name="deliveryItem"
                  checked={foodChecked}
                  type="checkbox"
                  value="Food"
                  onChange={(e) => setFoodChecked(e.target.checked)}
                />
                <label>Food</label>
              </section>
              <section className="other-check-mark">
                <input
                  checked={otherChecked}
                  name="deliveryItem"
                  type="checkbox"
                  value="Other"
                  ref={deliveryItemOtherRef}
                  onChange={(e) => setOtherChecked(e.target.checked)}
                />
                <label>Other</label>
              </section>
            </div>

            <div className="form-field">
              <label>
                Number of Package{' '}
                <span className="max-package">(Maximum 3)</span>
              </label>
              <input
                type="number"
                name="numberOfPackage"
                required
                ref={numberOfPackageRef}
                onChange={(e) => setNumberOfPackage(e.target.value)}
              />
            </div>

            <div className="form-field">
              <label>Delivery Date</label>
              <input
                type="date"
                name="deliveryDate"
                required
                ref={deliveryDateRef}
              />
            </div>

            <div className="form-field clinetInfo">
              <label>
                <span>Client 1 Info</span> (Name, Address, number, alternative
                number)
              </label>
              <textarea name="client1" required></textarea>
            </div>

            <div className="form-field clinetInfo">
              <label>
                <span>Client 2 Info</span> (Name, Address, number, alternative
                number)
              </label>
              <textarea name="client2"></textarea>
            </div>

            <div className="form-field clinetInfo">
              <label>
                <span>Client 3 Info</span> (Name, Address, number, alternative
                number)
              </label>
              <textarea name="client3"></textarea>
            </div>

            {/* hidden input box for name email address and phone */}
            <div className="hidden-input">
              <input type="text" name="name" ref={hiddenNameInput}></input>
              <input
                type="text"
                name="businessName"
                ref={hiddenBsnInput}
              ></input>
              <input
                type="text"
                name="address"
                ref={hiddenAddressInput}
              ></input>
              <input type="text" name="phone" ref={hiddenPhoneInput}></input>
            </div>

            <section className="request-delivery-btn">
              <button
                type="button"
                className="request-cancel-btn"
                onClick={(e) => handleSubmit('cancel', e)}
              >
                Cancel
              </button>
              <button className="request-submit-btn" type="submit">
                Submit
              </button>
            </section>
          </form>
        )}
      </section>
    </section>
  );
};

export default RequestDelivery;
