import { ref, update } from 'firebase/database';
import { useState } from 'react';
import { auth, db } from '../config/firebase-config';
import spinner from '../img/spinner.svg';

const UpdateDetailsForm = ({
  showEditBox,
  setShowEditBox,
  businessName,
  phone,
  address,
  setAddress,
  setBusinessName,
  setPhone,
}) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const user = auth.currentUser;

  const toggleEditBox = () => {
    setShowEditBox(!showEditBox);
  };

  const updateDetails = async (e) => {
    e.preventDefault();
    setLoading(true);

    const docRef = ref(db, '/users/' + user.uid);

    update(docRef, {
      businessName,
      phone,
      address,
    })
      .then(() => {
        setLoading(false);
        setShowEditBox(!showEditBox);
      })
      .catch((err) => {
        setLoading(false);
        setError(<p id="error">An error occured try again later</p>);
      });
  };

  return (
    <section className="update-form">
      {loading === true && (
        <section className="spinner">
          <img src={spinner} alt="loading.." />
        </section>
      )}

      <form onSubmit={updateDetails}>
        {/* show error */}
        {error}
        <div className="form-input">
          <label>Business Name</label>
          <input
            type="text"
            name="businessName"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </div>

        <div className="form-input">
          <label>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className="form-input">
          <label>Address</label>
          <textarea
            type="text"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <section className="btn">
          <button type="button" onClick={toggleEditBox} className="cancel-btn">
            close
          </button>

          <button type="submit" className="save-btn">
            Save
          </button>
        </section>
      </form>
    </section>
  );
};

export default UpdateDetailsForm;
