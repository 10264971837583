import reveiwsImg from '../img/reviews-img.svg';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import Slider from '../components/Reviewslider';
import { useEffect, useState } from 'react';
import '../css/reviews.css';
import { db } from '../config/firebase-config';
import { onValue, ref } from 'firebase/database';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [slideIndex, setSlideIndex] = useState(reviews.length - 1);

  useEffect(() => {
    const colRef = ref(db, '/reviews/');

    const getReviews = async () => {
      onValue(colRef, (response) => {
        const data = response.val();
        setReviews(data);
        setSlideIndex(data.length - 1);
      });
    };

    getReviews();
  }, []);

  const changeSlide = (direction) => {
    if (direction === 'next') {
      if (slideIndex !== reviews.length - 1) {
        setSlideIndex(slideIndex + 1);
      }
    } else if (direction === 'prev') {
      if (slideIndex !== 0) {
        setSlideIndex(slideIndex - 1);
      }
    }
  };
  // console.log(slideIndex);

  return (
    <section className="reviews_conatiner">
      <section className="review-resp-container">
        <section className="reviews-img">
          <img src={reveiwsImg} alt="review icon" />
        </section>

        <section className="reviews-slider">
          <section className="slider-header">
            <p>What People Say </p>
            <section className="slider-control">
              <IoIosArrowBack
                className="left"
                onClick={() => changeSlide('next')}
              />
              <IoIosArrowForward
                className="right"
                onClick={() => changeSlide('prev')}
              />
            </section>
          </section>

          <section className="slider-section">
            <Slider slideIndex={slideIndex} reviews={reviews} />
          </section>
        </section>
      </section>
    </section>
  );
};

export default Reviews;
